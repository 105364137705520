.post{
  background-color: rgb(145, 229, 156);
  margin: 2rem;
  padding: 1rem;
}
/* .pagination{
  margin: 1rem auto;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 50%;
} */
/* .active{
  border: 1px solid black;
  border-radius: 100%;
  padding: 0 3px;
  outline: none;
} */